import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, take } from 'rxjs';

import { RouterUrls } from '../../shared/router-urls';
import { AuthService } from '../../services/auth.service';
import { LanguageService } from '../../services/language.service';
import { CustomValidators, passwordValidator } from '../../shared/custom-validators';
import { TagsService } from 'src/app/services/tags.service';

import { MicrosoftUser, SsoCredentials, User } from '../../shared/user.model';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AuthConstants } from '../auth.constants';
import {
  GoogleLoginProvider,
  MicrosoftLoginProvider,
  SocialAuthService,
  SocialUser
} from '@abacritt/angularx-social-login';
import { Constants } from '../../app.constants';
import { FrontendLogService } from '../../services/frontend-log.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, AfterViewInit, OnDestroy {

  signUpForm: UntypedFormGroup;
  isCapsOn;
  isPasswordShown = false;
  isSsoReady = false;
  userInteracted = false;
  userSigningUp = false;
  passwordStrength: number;
  errorMessage: string;
  // Error logging
  ssoProviderInfo: any = {};
  emailInfo: string;
  // END Error logging
  clickEventSubscription: Subscription;
  socialAuthSubscription: Subscription;
  RouterUrls = RouterUrls;
  AuthConstants = AuthConstants;
  currentLang = 'fr';
  graphMeEndpoint = 'https://graph.microsoft.com/v1.0/me';
  signupUrl: string;
  autoRedirectToSamlSSO = false;

  constructor(private formBuilder: UntypedFormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private router: Router,
              private titleService: Title,
              private meta: Meta,
              private translate: TranslateService,
              private cd: ChangeDetectorRef,
              private languageService: LanguageService,
              private socialAuthService: SocialAuthService,
              private frontendLogService: FrontendLogService,
              private activatedRoute: ActivatedRoute,
              private tagsService: TagsService,
              private http: HttpClient,
  ) {
    this.clickEventSubscription = this.tagsService.getClickEventSignUp().subscribe(() => {
      this.updateTags();
    });
  }

  get name() {
    return this.signUpForm.get('name');
  }

  get email() {
    return this.signUpForm.get('email');
  }

  get confirmEmail() {
    return this.signUpForm.get('confirmEmail');
  }

  get password() {
    return this.signUpForm.get('password');
  }

  @HostListener('document:mousedown', ['$event'])
  detectUserInteraction() {
    this.userInteracted = true;
  }

  ngOnInit(): void {
    // Map url parameters to trigger actions like auto sso auth or assigning pledge id via URL parameter
    this.mapUrlParameters();
    this.setGoogleInteractionListener();
    this.updateTags();
    this.socialAuthService.initState.subscribe((isSsoReady) => {
      this.isSsoReady = isSsoReady;
    });
    this.socialAuthSubscription = this.socialAuthService.authState.subscribe({
      next: (user: SocialUser) => {
        if (this.userInteracted) {
          if (this.userSigningUp) return;
          this.userSigningUp = true;

          this.ssoProviderInfo.user = user;
          this.ssoSignUp(user, user.provider.toLowerCase());
        }
      },
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
    this.signUpForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: [''],
      email: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]],
      confirmEmail: ['', [Validators.required, Validators.pattern(CustomValidators.emailValidationRegex)]],
      password: ['', [Validators.required, passwordValidator()]],
      keepSignedIn: [false, []],
      agreement: [false, [Validators.requiredTrue]]
    }, {validators: CustomValidators.MustMatch('email', 'confirmEmail')});
  }

  ngAfterViewInit() {
    const userServiceSub = this.userService.getUser().subscribe((user: User) => {
      if (user) {
        user.action = 'login';
        this.authService.handleAuthentication(user);
        userServiceSub.unsubscribe();
      }
    });
    this.languageService.language$.asObservable().subscribe({
      next: (lang: string) => {
        this.currentLang = lang;
      }
    });
  }

  mapUrlParameters() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      this.signupUrl = queryParams.get('signup_url');
      this.autoRedirectToSamlSSO = queryParams.get('auto_saml') === 'true';
      if (this.autoRedirectToSamlSSO) {
        this.samlSignup()
      }
    });
  }

  socialSignUp(type: string): void {
    if (this.userSigningUp) return;
    this.userSigningUp = true;

    const ssoProvider = type === AuthConstants.SSO_GOOGLE ? GoogleLoginProvider.PROVIDER_ID : MicrosoftLoginProvider.PROVIDER_ID;
    const ssoProviderType = type === AuthConstants.SSO_GOOGLE ? AuthConstants.SSO_GOOGLE : AuthConstants.SSO_MICROSOFT;
    this.ssoProviderInfo.type = ssoProviderType;
    this.socialAuthService.signIn(ssoProvider)
      .then((user: SocialUser) => {
        this.ssoProviderInfo.user = user;

        if (user.provider === MicrosoftLoginProvider.PROVIDER_ID) {
          const headers = new HttpHeaders({
            'Authorization': `Bearer ${ user.authToken }`
          });

          this.http.get(this.graphMeEndpoint, {headers: headers}).subscribe(
            (msUser: MicrosoftUser) => {
              this.ssoSignUp(user, ssoProviderType, msUser);
            });
        } else {
          this.ssoSignUp(user, ssoProviderType);
        }
      })
      .catch((error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      });
  }

  samlSignup() {
    this.authService.goToSAMLAuth();
  }

  signUp() {
    const userCredentials = {
      email: this.signUpForm.value.email,
      name: this.signUpForm.value.name,
      surname: this.signUpForm.value.surname,
      password: this.signUpForm.value.password,
      language: this.translate.currentLang,
      signup_url: this.signupUrl
    };
    this.emailInfo = userCredentials.name + ' ' + userCredentials.surname + ' ' + userCredentials.email;
    this.authService.signUp(userCredentials).subscribe({
      next: (user: User) => {
        user.lac_only ?
          this.router.navigate([this.languageService.getCurrentLangWithSlash(), RouterUrls.Home]) :
          this.authService.openSignupModals();
      }
      ,
      error: (error: HttpErrorResponse) => {
        this.handleErrorResponse(error);
      }
    });
    this.signUpForm.reset();
  }

  /**
   * Initiates the sign-up process for users logging in through social sign-on (SSO).
   * This method constructs the SSO credentials, differentiates the sign-up flow
   * based on the provider, and handles the post-sign-up process.
   *
   * @param user - The social user object returned from the social login provider.
   * @param type - The type of social login provider (e.g., Google or Microsoft).
   * @param msUser - Optional parameter for Microsoft users containing additional user info.
   */
  ssoSignUp(user: SocialUser, type: string, msUser?: MicrosoftUser): void {
    // Initialize SSO credentials with common properties.
    const ssoCredentials: SsoCredentials = {
      idtoken: user.idToken,
      email: user.email,
      signup_url: this.signupUrl,
      type
    };

    // If Microsoft user data is available, add surname and language preferences.
    if (msUser) {
      ssoCredentials.surname = msUser.surname;
      ssoCredentials.lang = msUser.preferredLanguage ? msUser.preferredLanguage : this.currentLang;
    }

    // For Microsoft, we use the ssoLogin method for both sign-up and login,
    // and ssoSignUp for the other providers.
    const authServiceMethod = type === MicrosoftLoginProvider.PROVIDER_ID.toLowerCase() ?
      'ssoLogin' :
      'ssoSignUp';

    this.authService[authServiceMethod](ssoCredentials)
      .pipe(take(1))
      .subscribe({
        next: (user: User) => {
          this.authService.handleAuthentication(user, authServiceMethod === 'ssoLogin');
        },
        error: (error: HttpErrorResponse) => {
          this.handleErrorResponse(error);
        }
      });
  }

  updateTags() {
    this.titleService.setTitle(this.translate.instant('SIGN_UP_PAGE.meta_title_signup'));
    this.meta.updateTag({
      name: 'description',
      content: this.translate.instant('SIGN_UP_PAGE.meta_description_signup')
    });
    this.meta.updateTag({property: 'og:title', content: this.translate.instant('SIGN_UP_PAGE.meta_title_signup')});
    this.meta.updateTag({
      property: 'og:description',
      content: this.translate.instant('SIGN_UP_PAGE.meta_description_signup')
    });
    this.meta.updateTag({ property: 'og:type', content: 'URL' });
    this.meta.updateTag({ property: 'og:url', content: window.location.href });
    this.meta.updateTag({
      property: 'og:image',
      content: 'https://noos.ams3.digitaloceanspaces.com/meta%20_%20landing.png'
    });
  }

  changePassStrength(event) {
    this.passwordStrength = event;
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.socialAuthSubscription.unsubscribe();
  }

  // Needed to detect if the user clicked the Google signIn button iframe.
  private setGoogleInteractionListener(): void {
    window.addEventListener('blur', () => {
      setTimeout(() => {
        if (document.activeElement.tagName === 'IFRAME') {
          this.userInteracted = true;
        }
      });
    }, { once: true });
  }

  private handleErrorResponse(error: HttpErrorResponse): void {
    let showError = true;
    this.userSigningUp = false;

    if (error?.status === 400 || error?.status === 406 || error?.status === 409) {
      this.errorMessage = this.translate.instant('SIGN_UP_PAGE.error_account_already_exists');
    } else if (error?.error === AuthConstants.GOOGLE_SSO_CLOSED_POPUP_ERROR) {
      showError = false;
    } else {
      this.errorMessage = AuthConstants.AUTH_ERROR_UNKNOWN;

      this.frontendLogService.postLogEntry({
        component: Constants.SIGNUP_COMPONENT_NAME,
        file: Constants.SIGNUP_COMPONENT_NAME,
        function: Constants.HANDLE_ERROR_RESPONSE_FUNCTION_NAME,
        content: error
      }).subscribe();
    }
    this.email.setErrors({ emailExist: showError });

    this.frontendLogService.postLogEntry({
      component: Constants.SIGNUP_COMPONENT_NAME,
      file: Constants.SIGNUP_COMPONENT_NAME,
      function: Constants.HANDLE_ERROR_RESPONSE_FUNCTION_NAME,
      content: {
        error, browserData: {
          cookies: window.navigator.cookieEnabled,
          os: window.navigator.platform,
          browser: window.navigator.userAgent
        },
        userContext: {
          email: this.emailInfo,
          ssoProviderInfo: this.ssoProviderInfo,
          frontendUserError: this.errorMessage
        }
      }
    }).subscribe({
      complete: () => {
        this.ssoProviderInfo = {};
      }
    });
  }
}
